import styles from "./Project.module.css"
import Dialog from "@mui/material/Dialog"
import Typography from "@mui/material/Typography"
import Stack from "@mui/material/Stack"
import Button from "@mui/material/Button"
import Box from "@mui/material/Box"
import useTheme from '@mui/material/styles/useTheme';
import Carousel from 'react-material-ui-carousel';
import ResponsiveDialog from "components/ResponsiveDialogue";
import React from "react";
import DropDownMenu from "components/DropDownMenu";
import PanelTab, { PanelInterface } from "components/PanelTab";

interface ImageInterface {
    src: string
    setOpenDialog: React.Dispatch<React.SetStateAction<boolean>>;
}

function Image({ src, setOpenDialog }: ImageInterface): JSX.Element {
    return <Box className={styles.Image}>
        <img src={src} alt="demo" className={styles.img} onClick={() => setOpenDialog(true)} />
    </Box>
}

export interface projectDataInterface {
    title: string
    techs: string[],
    images: string[],
    panels: PanelInterface[],
    visitUrl: string | undefined,
    paperUrl: string | undefined,
    credit: string
}

interface ProjectInterface {
    data: projectDataInterface
}

function Project({ data: { techs, images, panels, title, visitUrl, paperUrl, credit } }: ProjectInterface): JSX.Element {
    const theme = useTheme();
    const [openDialog, setOpenDialog] = React.useState(false);
    const [openCredit, setOpenCredit] = React.useState(false);

    return <Stack direction="column" className={styles.Project}>
        <Stack direction="column">
            <Stack direction="row" alignItems="center" className={styles.title} >
                <Typography variant="h4" className={styles.projectName} >
                    {title}
                </Typography>
                <DropDownMenu title="Learn more">
                    {visitUrl ? <Button variant="text" size="small" href={visitUrl} target="_blank"> Visit </Button> : ""}
                    {paperUrl ? <Button variant="text" size="small" href={paperUrl} target="_blank"> Paper </Button> : ""}
                    <Button variant="text" size="small" onClick={() => setOpenCredit(!openCredit)}>
                        Credit
                    </Button>
                </DropDownMenu>
            </Stack>
            <Stack direction="row" className={styles.techContainer} sx={{ backgroundColor: theme.palette.secondary.main }} >
                {techs.map((title) => <Typography variant="body2" className={styles.tech} key={title} sx={{ color: theme.palette.secondary.contrastText }}>
                    <b>{title}</b>
                </Typography>
                )}
            </Stack>
            <Carousel animation="slide" autoPlay={false} height={300} className={styles.Carousel}>
                {images.map((src) => <Image src={src} key={src} setOpenDialog={setOpenDialog} />)}
            </Carousel>
            <PanelTab panels={panels} />
        </Stack>
        <ResponsiveDialog title={`${title} Screenshots`} setOpen={setOpenDialog} open={openDialog}>
            <Carousel autoPlay={false} animation="slide" height={600}>
                {images.map((src) => <Image src={src} key={src} setOpenDialog={setOpenDialog} />)}
            </Carousel>
        </ResponsiveDialog>
        <Dialog onClose={() => { setOpenCredit(false); setOpenDialog(false) }} open={openCredit} disableScrollLock>
            <Stack spacing={2} alignItems="flex-end" className={styles.credit}>
                <Typography variant='body2'>
                    Created along side {credit}
                </Typography>
                <Button variant="contained" sx={{ width: "fit-content" }} onClick={() => { setOpenCredit(false); setOpenDialog(false) }}>
                    Close
                </Button>
            </Stack>
        </Dialog>
    </Stack>
}

export default Project;
