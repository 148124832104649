import { Typography, Stack, List, ListItem, ListItemIcon } from "@mui/material"
import { projectDataInterface } from "sections/Project";
import styles from "sections/About.module.css"
import DoneOutlinedIcon from '@mui/icons-material/DoneOutlined';

const pixelboardFolder = `${process.env.PUBLIC_URL}/pixelboard`
export const pixelboardData: projectDataInterface = {
    title: "Pixel Board",
    techs: ["React", "TypeScript", "Nginx", "AWS EC2", "Node.js"],
    images: "1,2,arch".split(',').map((title) => `${pixelboardFolder}/${title}.png`),
    panels: [
        {
            label: "The Problem",
            id: "problem-panel",
            content: <Stack spacing={1}>
                <Typography variant="body1">
                    The <b>Paxos</b> algorithm solves the consensus problem in an asynchronous environment by sacrificing guaranteed termination.
                </Typography>
                <Typography variant="body1">
                    My team and I researched different variations of Paxos and Raft such as skipping the election step when exiting leader didn't fail. The pixel white board is used to demonstrate the algorithm in action, and the dev console showcases latency and connections in action.
                </Typography>
            </Stack>
        },
        {
            label: "Architecture",
            id: "arch-panel",
            content: <Stack spacing={1}>
                <Typography variant="body1">Unlike data replication and sharding, Paxos is a consensus algorithm that ensures the majority agrees on a consistent global state. Without an explicit leader, Paxos provides automatic failure and state recovery.</Typography>
                <Typography variant="body1">3 servers were setup because it is the minimum number of servers required to tolerate 1 server fault tolerance such that the remaining 2 servers forms a quorum. </Typography>
                <Typography variant="body1">Websocket is used to provide live updates.</Typography>
            </Stack>
        }
    ],
    visitUrl: "https://chuan.place/draw/",
    paperUrl: `${pixelboardFolder}/report.pdf`,
    credit: "Jonathan El-Khoury, and Kahsheng Lee",
}

const automatonFolder = `${process.env.PUBLIC_URL}/automaton-asset`
export const automatonData: projectDataInterface = {
    title: "Ball Automaton",
    techs: ["TypeScript", "p5.js"],
    images: "1,2".split(',').map((title) => `${automatonFolder}/${title}.png`),
    panels: [
        {
            label: "The Problem",
            id: "problem-panel",
            content: <Stack spacing={1}>
                <Typography variant="body1">
                    Visualizer for UMCPC x JaneStreet <a href={`${automatonFolder}/question.pdf`} target="_blank">heuristic problem</a>. Students are asked
                    to design a board state that will keep the ball on the board for the longest
                    amount of time.
                </Typography>
            </Stack>
        }
    ],
    visitUrl: "https://chuan.place/automaton/",
    paperUrl: undefined,
    credit: "Quang Ong",
}

const gnPoints = [
    "Dynamically generate webpages for different research",
    "Allow participants to sign up for different time slots",
    "Share documents and multi-media with researchers",
    "Tack participant's participation and monetary rewards",
]

export const gnData: projectDataInterface = {
    title: "Genyus Network",
    techs: ["React", "TypeScript", "Next.js", "Strapi", "Material UI"],
    images: "1,2,3,4,5,arch".split(',').map((title) => `${process.env.PUBLIC_URL}/gn/${title}.png`),
    panels: [
        {
            label: "The Problem",
            id: "problem-panel",
            content: <Stack spacing={1}>
                <Typography variant="body1">
                    Genyus Network gathers data for longitudinal studies by fostering patient lead support groups. The web application is a management tool with the following core functionalities:
                </Typography>
                <List dense>
                    {gnPoints.map((text) => <ListItem key={text}>
                        <ListItemIcon className={styles.listIcon}>
                            <DoneOutlinedIcon color="primary" fontSize="small" />
                        </ListItemIcon>
                        <Typography variant="body1">
                            {text}
                        </Typography>
                    </ListItem>
                    )}
                </List>
            </Stack>
        },
        {
            label: "Architecture",
            id: "arch-panel",
            content: <Stack spacing={1}>
                <Typography variant="body1">
                    <b>NoSQL</b> was the choice of storage because in the absence of a strict schema, the user can add more fields to the dynamically generated webpages without breaking existing webpages.
                </Typography>
                <Typography variant="body1">
                    To reduce loading time, Next.js was used to <b>server-side render</b> React.
                </Typography>
                <Typography variant="body1">
                    Google Drive provides an economical multi-media storage option with the added security guarantee.
                </Typography>
            </Stack>
        }
    ],
    visitUrl: "https://gn-frontend.vercel.app/",
    paperUrl: undefined,
    credit: "Samuel Webster, Nicolas Montorio, Yang Zhou, Jiacheng Ye, Pei-Chen Chen, Mingyu Su, Tean Surasak, Zhentao He, Luke Rosa, and Callum Dowling.",
}

export const heirloomData: projectDataInterface = {
    title: "Heirloom22",
    techs: ["React", "TypeScript", "Next.js", "Strapi", "Material UI"],
    images: "1,2,3,arch".split(',').map((title) => `${process.env.PUBLIC_URL}/heirloom/${title}.png`),
    panels: [
        {
            label: "The Problem",
            id: "problem-panel",
            content: <Stack spacing={1}>
                <Typography variant="body1">
                    The client wanted to track family history by storing a digital record of heirlooms and photos.
                </Typography>
                <Typography variant="body1">
                    Heirloom22 has similar functionality as a private Instagram account such as tags and comments.
                </Typography>
            </Stack>
        },
        {
            label: "Architecture",
            id: "arch-panel",
            content: <Stack spacing={1}>
                <Typography variant="body1">
                    Social media websites are often read-intensive, thus NoSQL was the choice of data storage.
                </Typography>
                <Typography variant="body1">
                    Websocket was used to provide live updates.
                </Typography>
            </Stack>
        }
    ],
    visitUrl: "https://heirloom22-2b4a8.web.app/",
    paperUrl: undefined,
    credit: "Sable Wang-Wills, Luoming Zhang, and Anqi Chen"
}

export const edtechData: projectDataInterface = {
    title: "EdTech",
    techs: ["React", "JavaScript", "Node.js", "MySQL"],
    images: "1,2,3,4,arch".split(',').map((title) => `${process.env.PUBLIC_URL}/edtech/${title}.png`),
    panels: [
        {
            label: "The Problem",
            id: "problem-panel",
            content: <Stack spacing={1}>
                <Typography variant="body1">
                    The client wished to digitize the assessment rubrics.
                </Typography>
                <Typography variant="body1">
                    The web application provides an interface for marking and commenting on assessment rubrics. The data collected can be printed or exported as CSV.
                </Typography>
            </Stack>
        },
        {
            label: "Architecture",
            id: "arch-panel",
            content: <Stack spacing={1}>
                <Typography variant="body1">
                    My team and I chose React and Node.js to keep the front-end and back-end codebase consistent.
                </Typography>
            </Stack>
        }
    ],
    visitUrl: undefined,
    paperUrl: undefined,
    credit: "Samuel Tumewa, Anuradha Gupta, Yuyao Ma, and Zijian Ju"
}

const chatFolder = `${process.env.PUBLIC_URL}/gpchat-asset`
export const gpchatData: projectDataInterface = {
    title: "GPChat",
    techs: ["React", "TypeScript", "NextJS", "TailwindCSS", "DaisyUI", "Heroicons"],
    images: "1,2".split(',').map((title) => `${chatFolder}/${title}.png`),
    panels: [
        {
            label: "The Problem",
            id: "problem-panel",
            content: <Stack spacing={1}>
                <Typography variant="body1">
                    When consulting a doctor, the patient often find it difficult to provide symptoms with accurate descriptions.
                </Typography>
                <Typography variant="body1">
                    The GPChat app allows the user to record their symptoms with a visual anatonmy guide that is shared with the doctor.
                </Typography>
                <Typography variant="body1">
                    AI is used to both help record the symptoms and provide a preliminary healthcare advice.
                </Typography>
            </Stack>
        },
    ],
    visitUrl: "https://chuan.place/gpchat",
    paperUrl: undefined,
    credit: ""
}

const flashcardFolder = `${process.env.PUBLIC_URL}/flashcard`
export const flashcardData: projectDataInterface = {
    title: "AI Flashcard",
    techs: ["React", "TypeScript", "Tailwind", "Convex", "OpenAI", "Clark"],
    images: "1,2,arch".split(',').map((title) => `${flashcardFolder}/${title}.png`),
    panels: [
        {
            label: "The Problem",
            id: "problem-panel",
            content: <Stack spacing={1}>
                <Typography variant="body1">
                    When asking ChatGPT questions, I often find myself wanting to save the answers
                    as flashcards so that I can revisit them. This hackathon submission is the MVP
                    to test our concept.
                </Typography>
                <Typography variant="body1">
                    This was a submission to Web Dev Cody's <a href="https://www.youtube.com/watch?v=9D4KoGW0H78&ab_channel=WebDevCody" target="_blank">Convex Hackathon</a>.
                </Typography>
            </Stack>
        },
        {
            label: "Architecture",
            id: "arch-panel",
            content: <Stack spacing={1}>
                <Typography variant="body1">Clerk used for authentication as a service.</Typography>
                <Typography variant="body1">Convex used for server and database as a service. The server and database were configured using server functions and database schema</Typography>
            </Stack>
        }
    ],
    visitUrl: "https://chuan.place/ai-flashcards",
    paperUrl: undefined,
    credit: "Steven Dong",
}
