import * as React from 'react';
import Button from "@mui/material/Button"
import Popper from "@mui/material/Popper"
import Paper from "@mui/material/Paper"
import ClickAwayListener from "@mui/material/ClickAwayListener"
import styles from "./DropDownMenu.module.css"
import KeyboardArrowUp from "@mui/icons-material/KeyboardArrowUp"
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown'

interface DropDownMenuInterface {
    title: string,
    children: React.ReactNode
}

function DropDownMenu({ title, children }: DropDownMenuInterface) {
    const anchorRef = React.useRef(null)
    const [open, setOpen] = React.useState(false);

    return (
        <div>
            <Button variant="outlined" size='small' onClick={() => setOpen(!open)} ref={anchorRef}
                sx={{
                    border: "0.1rem yellowgreen solid",
                    color: "yellowgreen"
                }}
                endIcon={open ? <KeyboardArrowUp /> : <KeyboardArrowDown />} >
                {title}
            </Button>
            <Popper open={open} placement="bottom-end" anchorEl={anchorRef?.current} disablePortal={true} className={styles.Popper}
                modifiers={[{ name: 'flip', enabled: false, }, { name: 'preventOverflow', enabled: false, }]}>
                <ClickAwayListener onClickAway={() => setOpen(false)}>
                    <Paper elevation={6} sx={{ marginTop: "0.5rem" }}>
                        {children}
                    </Paper>
                </ClickAwayListener>
            </Popper>
        </div>
    );
}

export default DropDownMenu;
