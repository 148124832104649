import * as React from 'react';
import Button from "@mui/material/Button"
import Typography from "@mui/material/Typography"
import Box from "@mui/material/Box"
import ThemeSwitch from "components/ThemeSwitch";
import useTheme from '@mui/material/styles/useTheme';
import { scrollToType, sectionDictInterface } from "App";
import styles from "./NavBar.module.css"
import { scrollIntoView } from 'utils/pollyfill';

interface NavBarInterface {
    sectionDict: sectionDictInterface,
    navScrollTo: scrollToType,
    setContentScrollTo: React.Dispatch<React.SetStateAction<scrollToType>>,
    setIsLightTheme: React.Dispatch<React.SetStateAction<boolean>>,
    isLightTheme: boolean,
}

function NavBar({ sectionDict, navScrollTo, setContentScrollTo, setIsLightTheme, isLightTheme }: NavBarInterface): JSX.Element {
    const theme = useTheme();

    const barRef = React.useRef<HTMLElement>()
    React.useEffect(() => {
        centerButton(navScrollTo.id)
    }, [navScrollTo])

    // center the button corresponding to the current section on nav bar
    function centerButton(id: string) {
        const bar = barRef?.current
        const button = bar?.querySelector(`#${id}-button`)
        if (button && bar) {
            scrollIntoView(bar, button, true, true)
        }
    }

    function onClickBuilder(id: string) {
        return () => {
            setContentScrollTo({ id: id, toggle: false })
        }
    }

    function toggleTheme() {
        localStorage.setItem('theme', (!isLightTheme).toString())
        setIsLightTheme(!isLightTheme)
    }

    return (
        <Box className={styles.NavBar} bgcolor="background.navBar" sx={{ boxShadow: theme.palette.background.shadow }}>
            <Box className={styles.bar} ref={barRef}>
                <Box className={`${styles.textContainer} unselectable`}>
                    {Object.values(sectionDict).map(({ title, id }) => (
                        <Button key={title} id={`${id}-button`} variant="text" color="info" onClick={onClickBuilder(id)} sx={{ mr: "1rem" }} >
                            <Typography variant="body1" noWrap>
                                {id === navScrollTo.id ? <u>{title}</u> : title}
                            </Typography>
                        </Button>
                    ))}
                </Box>
                <ThemeSwitch onChange={toggleTheme} checked={!isLightTheme} />
            </Box>
        </Box>
    );
};

export default NavBar;
