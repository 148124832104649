import NavBar from "./components/NavBar";
import Content from "./components/Content";
import ThemeProvider from "@mui/material/styles/ThemeProvider";
import { DARK_THEME, LIGHT_THEME } from "theme/CustomTheme";
import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"
import Stack from "@mui/material/Stack"
import Link from "@mui/material/Link"
import Contact from "./sections/Contact";
import About from "./sections/About";
import Project from "./sections/Project";
import { pixelboardData, gnData, heirloomData, edtechData, automatonData, flashcardData, gpchatData } from "data";
import Section from "./components/Section";
import "./App.css"
import React from "react";

export interface sectionType {
    id: string, title: string, ref: React.MutableRefObject<HTMLElement | undefined>
}
export interface sectionDictInterface { [id: string]: sectionType }
export interface scrollToType {
    id: string,
    toggle: boolean;
}

function App(): JSX.Element {
    const [sectionDict] = React.useState<sectionDictInterface>({
        'contact': {
            id: 'contact',
            title: "Contact",
            ref: React.useRef<HTMLElement>()
        },
        'about': {
            id: 'about',
            title: "About Me",
            ref: React.useRef<HTMLElement>()
        },
        'projects': {
            id: 'projects',
            title: 'Projects',
            ref: React.useRef<HTMLElement>()
        },
    })

    React.useEffect(() => {

        // [ "http:", "", "localhost:3000", "#about" ]
        const urlArray = window.location.href.split("/")
        const section = urlArray.filter((e) => e[0] === "#")[0]
        if (section) {
            const scrollPos = { id: section.substring(1), toggle: false }
            setNavScrollTo(scrollPos);
            setContentScrollTo(scrollPos)
        }

        document.documentElement.style.setProperty('--fh', `${window.innerHeight}px`);
        function handleResize() {
            document.documentElement.style.setProperty('--fh', `${window.innerHeight}px`);
        }
        window.addEventListener('resize', handleResize)
        return () => window.removeEventListener('resize', handleResize);

    }, [])


    const [navScrollTo, setNavScrollTo] = React.useState<scrollToType>(
        { id: Object.keys(sectionDict)[0], toggle: false }
    );
    const [contentScrollTo, setContentScrollTo] = React.useState<scrollToType>(
        { id: Object.keys(sectionDict)[0], toggle: false }
    );

    const [isLightTheme, setIsLightTheme] = React.useState<boolean>(false)

    React.useEffect(() => {
        const theme = localStorage.getItem('theme');
        if (theme !== undefined) setIsLightTheme(theme === 'true');
    }, [setIsLightTheme])

    return <ThemeProvider theme={isLightTheme ? LIGHT_THEME : DARK_THEME}>
        <Box className="App" bgcolor="background.default">
            <NavBar sectionDict={sectionDict} setContentScrollTo={setContentScrollTo} navScrollTo={navScrollTo} isLightTheme={isLightTheme} setIsLightTheme={setIsLightTheme} />
            <Content sectionDict={sectionDict} setNavScrollTo={setNavScrollTo} contentScrollTo={contentScrollTo} >
                <Section section={sectionDict['contact']}>
                    <Contact />
                </Section>
                <Section section={sectionDict['about']}>
                    <About />
                </Section>
                <Section section={sectionDict['projects']}>
                    <Project data={gpchatData} />
                    <Project data={flashcardData} />
                    <Project data={automatonData} />
                    <Project data={pixelboardData} />
                    <Project data={gnData} />
                    <Project data={heirloomData} />
                    <Project data={edtechData} />
                    <Stack height="100px" alignItems="center" direction="column" justifyContent="flex-end">
                        <Typography variant="body2" textAlign="center">
                            Created by Chuan 🍵 using <Link href="https://mui.com" target="_blank">Material UI</Link> and <Link href="https://github.com/leandrowd/react-responsive-carousel" target="_blank"> React Carousel </Link>
                        </Typography>
                        <Typography variant="body2">
                            designed using <Link href="https://www.figma.com" target="_blank">Figma</Link> and <Link href="https://procreate.art" target="_blank">Procreate</Link>
                        </Typography>
                        <Typography variant="body2">
                            updated on 03/06/2024
                        </Typography>
                    </Stack>
                </Section>
            </Content>
        </Box>
    </ThemeProvider >
}

export default App;
