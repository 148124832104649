export function scrollIntoView(parent: Element, child: Element, horizontal: boolean = false, center: boolean = false) {

    const pBound = parent.getBoundingClientRect();
    const cBound = child.getBoundingClientRect();

    var centerOffset = 0;
    if (horizontal) {
        if (center) centerOffset = (pBound.width - cBound.width) / 2;
        parent.scrollTo({
            top: 0, left: parent.scrollLeft + cBound.left - pBound.left - centerOffset, behavior: "smooth",
        });
    } else {
        if (center) centerOffset = (pBound.width - cBound.height) / 2;
        parent.scrollTo({
            top: parent.scrollTop + cBound.top - pBound.top - centerOffset, left: 0, behavior: "smooth",
        });
    }
};
