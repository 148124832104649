import React from 'react';
import Typography from '@mui/material/Typography';
import styles from "./Header.module.css"

interface HeaderPropsType {
    children: React.ReactNode;
}

function Header({ children }: HeaderPropsType): JSX.Element {
    return <Typography variant="h6" className={styles.Header} gutterBottom>
        {children}
    </Typography>
}

export default Header;