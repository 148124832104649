import React from "react";
import styles from "./Section.module.css";
import Stack from "@mui/material/Stack";
import { sectionType } from "App";

interface SectionInterface {
    section: sectionType,
    children: React.ReactNode
}

function Section({ section: { id, ref }, children }: SectionInterface): JSX.Element {
    return <Stack spacing={10} className={styles.Section} id={id} ref={ref}>
        {children}
    </Stack>
}

export default Section;