import Typography from "@mui/material/Typography"
import Stack from "@mui/material/Stack"
import Button from "@mui/material/Button"
import Box from "@mui/material/Box"
import List from "@mui/material/List"
import ListItem from "@mui/material/ListItem"
import ListItemIcon from "@mui/material/ListItemIcon"
import Header from "components/Header";
import styles from "./About.module.css";
import CodeOutlinedIcon from '@mui/icons-material/CodeOutlined';
import React from "react";

interface AboutInterface {
}

const languages1 = "TypeScript, Javascript, Java, C, Python".split(", ");
const languages2 = "React, Node.js, AWS EC2, Nginx, Linux".split(", ");

const images = {
    stationary: `${process.env.PUBLIC_URL}/chuan/stationary.png`,
    running: `${process.env.PUBLIC_URL}/chuan/running.png`,
    waiting: `${process.env.PUBLIC_URL}/chuan/waiting.png`,
    programming: `${process.env.PUBLIC_URL}/chuan/programming.png`,
}

function About({ }: AboutInterface) {
    const [image, setImage] = React.useState(images.stationary)
    const aboutRef = React.useRef<HTMLElement>()

    // stop animation aftersome time
    React.useEffect(() => {
        let timer: NodeJS.Timeout;
        if (image == images.programming) {
            timer = setTimeout(() => {
                setImage(images.stationary)
            }, 6300)
        }
        else if (image == images.running) {
            timer = setTimeout(() => {
                setImage(images.stationary)
            }, 8000)
        }
        else if (image == images.waiting) {
            timer = setTimeout(() => {
                setImage(images.stationary)
            }, 4300)
        }
        else if (image == images.stationary) {
            timer = setTimeout(() => {
                setImage(images.waiting)
            }, 10000)
        }
        return () => clearTimeout(timer)
    }, [image])

    return <Box className={styles.About} ref={aboutRef}>
        <Header>
            About Me
            <Button onClick={() => setImage(images.programming)} startIcon="💾">program</Button>
            <Button startIcon="👟" onClick={() => setImage(images.running)}>exercise</Button>
        </Header>
        <Box className='container'>
            <div className='imageContainer'>
                <img src={image} className={styles.wrappedImage} alt="chuan" />
            </div>
            <Typography variant="body1" marginBottom="10px">
                You can call me Chuan and I enjoy problem solving. It feels great to speed up a
                website by seconds using the correct React hooks, and reduce processing time by
                applying the suitable algorithms.
            </Typography>
            <Typography variant="body1" marginBottom="10px">
                During my studies, I was honored to work alongside with extremely talented students.
                I learnt <b>algorithms</b>, <b>data analysis</b>, <b>system architecture</b>, <b>cryptography</b>, and many more.
                Some of the projects and research that I have done are listed below.
            </Typography>
            <Typography variant="body1">
                Recently, I have been using the following technologies:
            </Typography>
        </Box>
        <Box className={styles.listContiner}>
            <List dense={true}>
                {languages1.map((text) => <ListItem dense={true} key={text}>
                    <ListItemIcon className={styles.listIcon} >
                        <CodeOutlinedIcon color="primary" fontSize="small" />
                    </ListItemIcon>
                    <Typography variant="body2">
                        {text}
                    </Typography>
                </ListItem>
                )}
            </List>
            <List dense={true}>
                {languages2.map((text) => <ListItem dense key={text}>
                    <ListItemIcon className={styles.listIcon} >
                        <CodeOutlinedIcon color="primary" fontSize="small" />
                    </ListItemIcon>
                    <Typography variant="body2">
                        {text}
                    </Typography>
                </ListItem>
                )}
            </List>
        </Box >
    </Box >
}

export default About;
