import Box from "@mui/material/Box";
import styles from "./Content.module.css";
import { scrollIntoView } from "utils/pollyfill";
import { sectionDictInterface, scrollToType } from "App";
import React from "react";

interface ContentPropsTypes {
    children?: React.ReactNode,
    sectionDict: sectionDictInterface,
    contentScrollTo: scrollToType,
    setNavScrollTo: React.Dispatch<React.SetStateAction<scrollToType>>,
}

function remToPx(rem: number) {
    return rem * parseFloat(getComputedStyle(document.documentElement).fontSize);
}

function Content({ children, sectionDict, setNavScrollTo, contentScrollTo }: ContentPropsTypes): JSX.Element {
    const viewPortRef = React.useRef<HTMLDivElement>(null);

    // Auto scrolling
    React.useEffect(() => {
        const section = sectionDict[contentScrollTo.id]?.ref?.current;
        const viewport = viewPortRef?.current;
        if (section == undefined || viewport == undefined) {
            return
        }
        scrollIntoView(viewport, section)
    }, [contentScrollTo, sectionDict])

    // Switch section selected by scrolling
    React.useEffect(() => {
        const viewport = viewPortRef?.current;
        const onScroll = () => {
            let shortedDist = Infinity
            let closest = ""
            const viewPortHeight = parseFloat(getComputedStyle(document.documentElement).getPropertyValue('--fh'));
            const halfHeight = remToPx(3) + (viewPortHeight - remToPx(3)) / 2;
            for (const { ref, id } of Object.values(sectionDict)) {
                if (ref.current) {
                    const bound = ref.current.getBoundingClientRect()
                    if (bound.top < halfHeight && halfHeight - bound.top < shortedDist) {
                        shortedDist = halfHeight - bound.top
                        closest = id
                    }
                }
            }
            setNavScrollTo({ id: closest, toggle: false })
        }
        if (viewport) {
            viewport.addEventListener("scroll", onScroll)
        }
        return () => viewport?.removeEventListener("scroll", onScroll)
    }, [sectionDict, setNavScrollTo])

    return <Box className={styles.Content} ref={viewPortRef}>
        <Box className={styles.viewPort} bgcolor="background.content">
            {children}
        </Box>
    </Box>
}

export default Content;
