import * as React from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import Stack from '@mui/material/Stack';
import DialogContent from '@mui/material/DialogContent';
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import useTheme from '@mui/material/styles/useTheme';
import styles from './ResponsiveDialogue.module.css';
import CloseIcon from '@mui/icons-material/Close';

interface ResponsiveDialogInterface {
    setOpen: React.Dispatch<React.SetStateAction<boolean>>
    open: boolean,
    title: string,
    children: React.ReactNode
}

function ResponsiveDialog({ open, setOpen, title, children }: ResponsiveDialogInterface): JSX.Element {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    return <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="responsive-dialog-title"
        maxWidth="md"
        fullWidth={true}
    >
        <Stack id="responsive-dialog-title" direction="row" justifyContent="space-between" alignItems="center" bgcolor="background.navBar" className={styles.header}>
            <Typography variant="h6" noWrap>
                {title}
            </Typography>
            <Button onClick={() => setOpen(false)} className={styles.button} size="small">
                <CloseIcon />
            </Button>
        </Stack>
        <DialogContent>
            {children}
        </DialogContent>
    </Dialog>
}

export default ResponsiveDialog;
