import Typography from "@mui/material/Typography"
import Button from "@mui/material/Button"
import Box from "@mui/material/Box"
import styles from "./Contact.module.css"
import React from "react";
interface ContactsInterface {
}

const prompts = [
    "I love building software and solving problems.",
    "Algorithms are fun!",
    "Python, ReactJS, and Golang are my jams :D",
]

function Contact(args: ContactsInterface) {

    const typewriteRef = React.useRef(null);
    const [intro, setIntro] = React.useState("")
    const [index, setIndex] = React.useState(0)
    const [isDeleting, setIsDeleting] = React.useState(false)

    React.useEffect(() => {
        let timer: NodeJS.Timeout;
        if (intro.length === 0 && isDeleting) {
            setIsDeleting(false)
            setIndex((index + 1) % prompts.length)
        }
        else if (intro.length === prompts[index].length) {
            setIsDeleting(true)
        }

        if (isDeleting) {
            const waitingTime = intro.length == prompts[index].length ? 5000 : 100
            timer = setTimeout(() => {
                setIntro(prompts[index].substring(0, intro.length - 1))
            }, waitingTime)
        } else {
            timer = setTimeout(() => {
                setIntro(prompts[index].substring(0, intro.length + 1))
            }, 200)
        }
        return () => clearTimeout(timer)
    }, [index, intro, isDeleting])


    return <Box className={styles.Contact}>
        <Typography variant="body1">
            Hi, my name is
        </Typography>
        <Typography variant="h3">
            Chuanyuan Liu.
        </Typography>
        <Typography variant="h4" ref={typewriteRef} className={styles.typewrite}>
            {intro} <span className={styles.cursor} /><span className={styles.hidden}>.</span>
        </Typography>
        <Typography variant="body1">
            A full stack software engineer more than 2 years of work experience. My focus is on creating
            efficient and scalable distributed systems, and developing user friendly website.
        </Typography>
        <Box className={styles.buttonContainer}>
            {/* <Button variant="contained" href={`${process.env.PUBLIC_URL}/chuanyuan-cv.pdf`} target="_blank">
                My CV
            </Button> */}
            <Button variant="contained" href="https://www.linkedin.com/in/chuanyuan/" target="_blank">
                LinkedIn
            </Button>
        </Box>
    </Box>
}

export default Contact;
