import * as React from 'react';
import styles from "./PanelTab.module.css"
import Box from "@mui/material/Box"
import Stack from "@mui/material/Stack"
import Typography from "@mui/material/Typography"
import { scrollIntoView } from 'utils/pollyfill';

export interface PanelInterface {
    label: string,
    id: string,
    content: React.ReactNode
}

interface PanelTabInterface {
    panels: PanelInterface[]
}

function PanelTab({ panels }: PanelTabInterface) {
    const viewPortRef = React.useRef<HTMLElement>();
    const [currentPanelID, setCurrentPanelID] = React.useState<string>(panels[0].id)

    const display = (id: string) => {
        const viewport = viewPortRef?.current
        const container = viewPortRef?.current?.querySelector(`#${id}`)
        if (viewport == undefined || container == undefined) {
            return
        }
        setCurrentPanelID(id)
        scrollIntoView(viewport, container, true, false)
    }

    return (
        <Box>
            <Stack direction="row">
                {panels.map(({ label, id }) => <Typography key={id} variant="body1" onClick={() => display(id)}
                    className={id == currentPanelID ? `${styles.tab} ${styles.glass}` : styles.tab}>
                    {label}
                </Typography>)}
            </Stack>
            <Box ref={viewPortRef} className={`${styles.viewPort} ${styles.glass}`}>
                {
                    panels.map(({ content, id }) => <Box role="tabpanel" key={id} id={id} className={styles.panel} >
                        {content}
                    </Box>
                    )
                }
            </Box>

        </Box>
    );
}

export default PanelTab;
