import createTheme from '@mui/material/styles/createTheme';

declare module "@mui/material/styles/createPalette" {
    interface TypeBackground {
        navBar: string;
        content: string;
        shadow: string;
    }
}

const DARK_THEME = createTheme({
    palette: {
        mode: "dark",
        primary: {
            main: "#d8dee9",
        },
        info: {
            main: "#d8dee9",
        },
        secondary: {
            main: "#d8dee9",
            contrastText: "#4c566a"
        },
        background: {
            default: "#121212",
            content: "#292e39",
            navBar: "#2e3440",
            shadow: "rgba(36, 41, 51, 0.15) 0px 5px 10px 0px",
        },
    },
    typography: {
        allVariants: {
            color: "#d8dee9",
        },
        fontFamily: [
            'Inter',
            'sans-serif'
        ].join(','),
        button: {
            textTransform: 'none'
        }
    }
});

const LIGHT_THEME = createTheme({
    palette: {
        mode: "light",
        primary: {
            main: "#4c566a",
        },
        info: {
            main: "#f8f9fb",
        },
        secondary: {
            main: "#4c566a",
            contrastText: "#ffffff"
        },
        background: {
            default: "#ffffff",
            content: "#fbfbfc",
            navBar: "#ffffff",
            shadow: "rgba(216, 222, 233, 0.15) 0px 5px 10px 0px",
        },
    },
    typography: {
        fontFamily: [
            'Inter',
            'sans-serif'
        ].join(','),
        allVariants: {
            color: "#4c566a",
        },
        button: {
            textTransform: 'none'
        }
    }
});

export { DARK_THEME, LIGHT_THEME }
